import "./OurPeopleIntro.scss"
export default function OurPeopleIntro({ name, title, description }) {
  return (
    <div className="our-people-intro">
      <div className="ceo-name">{name}</div>
      <div className="ceo-title">{title}</div>
      <div
        className="ceo-description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  )
}
